import { AtlasInfo, AtlasLoading, AtlasResultsHeader, AtlasTags, CampusSort, CampusSortProps } from 'atlas-ds'
import Link from 'next/link'
import { Selector } from 'ui/selectors'

interface ResultsFiltersProps<F> {
    filtres: F
    changeFiltres: (f: F) => void
    selectors: {
        key: string,
        selector: Selector<F>,
        full?: boolean
    }[]
    resetFiltres: () => void
}

interface ResultsProps<F> {
    children: JSX.Element
    count: number
    pageSize: number
    loading?: boolean
    error?: any
    sort?: CampusSortProps
    filtres?: ResultsFiltersProps<F>
    hideHeader?: boolean
    pagingUrl?: (page: number) => string
}

export default function Results<F>(props: ResultsProps<F>) {
    const allSelectors = (props.filtres?.selectors ?? [])
        .reduce((allSelectors, selector) =>
            allSelectors.concat(selector.selector),
            [] as Selector<F>[]
        )

    const ResultsContent = () => {
        if (!props.loading && props.error) {
            return <AtlasInfo type="error" title="Erreur">
                Une erreur s'est produite pendant cette requête. Nous vous invitons à réessayer.
            </AtlasInfo>
        } else if (!props.loading && props.count === 0) {
            return <AtlasInfo type="error" title="Aucun résultat">
                Nous n'avons trouvé aucun résultat pour cette requête. Nous vous invitons à réessayer.
            </AtlasInfo>
        }

        return props.children
    }

    const pageCount = (props.count % props.pageSize) === 0 ? props.count/props.pageSize : Math.floor(props.count/props.pageSize)+1
    const pageArray = pageCount && !isNaN(pageCount) ? new Array(pageCount) : []
    const pages = Array.from(pageArray.keys())
    if (pages.length > 0) {
        pages.shift()
    }

    const filters = allSelectors
        .filter((selector) => selector && selector.isSelected(props.filtres!.filtres))
        .map((selector) => selector.selected(props.filtres!.filtres, props.filtres!.changeFiltres))
        .flat()

    // console.log("Results: loading = " + props.loading)

    return <AtlasLoading loading={props.loading === true}>
        { /* Liens cachés pour référencement !?! */ props.pagingUrl && pages && pages.length > 0 && <div style={{ display: 'none' }}>
            { pages.map(p => <Link key={ `hiddenPageLink${p}` } href={ props.pagingUrl!(p)}>{ p }</Link>) }
        </div> }

        <>
            { !props.hideHeader && <AtlasResultsHeader
                count={props.count}
                filters={filters.length ? <AtlasTags>{filters}</AtlasTags> : undefined}
                onResetFilters={props.filtres?.resetFiltres}
                sortOptions={ props.sort && <CampusSort { ...props.sort } /> }
            /> }
            <ResultsContent />
        </>
    </AtlasLoading>
}
